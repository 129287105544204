import type { QuestionSolution } from "@resposta-certa/api/src/modules/resoluter/resoluter.service";
import { defineStore } from "pinia";

interface BlockState {
  isLoading: boolean;
  isComputing: boolean;
  isLimitOpen: boolean;
  questionNumber: number;
  answerStatus: "pending" | "correct" | "incorrect";
  selectedAnswer: AnswerLetters | null;
  currentSolution: QuestionSolution | null;
  isCelebrationOpen: boolean;
  scoreParams: {
    corrects: number;
    time: number;
    currentGrade: number;
    gradeChange: number;
    open: boolean;
  };
  questions: BlockQuestions;
}

export const useBlockStore = defineStore("block", {
  state: (): BlockState => ({
    isLoading: true,
    isComputing: false,
    isLimitOpen: false,
    questionNumber: 0,
    answerStatus: "pending",
    isCelebrationOpen: false,
    selectedAnswer: null,
    currentSolution: null,
    scoreParams: {
      corrects: 0,
      time: 0,
      currentGrade: 0,
      gradeChange: 0,
      open: false,
    },
    questions: [],
  }),
  getters: {
    stepNumber(state) {
      let number = state.questionNumber + (5 - state.questions.length);

      // if (state.answerStatus !== "pending") {
      //   number += 1;
      // }

      return state.isLimitOpen ? 0 : number;
    },
    currentQuestion(state) {
      if (state.isLoading) return null;

      const question = state.questions[state.questionNumber];

      if (!question) return null;

      const answers = Object.keys(question.answers).map((key) => ({
        letter: key as AnswerLetters,
        body: question.answers[key as AnswerLetters],
        isCorrect: question.answer === key,
      }));

      return {
        ...state.questions[state.questionNumber],
        answers,
      };
    },
  },
});
